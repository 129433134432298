import React from 'react';
import messages from '../messages/common';
import YourceLogo from '../assets/images/logo-yource-com.inline.svg';
import { useIntl } from 'react-intl';
import config from '../config.json';
import { useCoBrandingParams } from '../hooks/useCobrandingParams';

const OTAHeaderLogo = () => {
  const { formatMessage } = useIntl();
  const { logoUrl, dynamicConfig } = useCoBrandingParams();

  return (
    <a
      href={'//' + dynamicConfig.portalUrl}
      className="no-underline w-auto float-left block flex items-center"
    >
      <img
        src={logoUrl || config.siteLogo}
        alt="Logo"
        className="ml-12 sm:ml-0 h-35 sm:h-50 inline-block mr-20"
      />
      <p className="text-xxs text-black m-0 max-w-120 block w-full">
        {formatMessage(messages.poweredByYource, {
          // eslint-disable-next-line
          strong: (...chunks) => (
            <strong className="text-xs font-bold uppercase">{chunks}</strong>
          ),
        })}
        <YourceLogo className="pl-5 sm:ml-0 h-15 inline-block m-0" />
      </p>
    </a>
  );
};

export default OTAHeaderLogo;
