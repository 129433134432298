import React from 'react';
import { useLocation } from '@reach/router';
import config from '../config.json';
import messages from '../messages/common';
import YourceLogo from '../assets/images/logo-yource-com.inline.svg';
import { useIntl } from 'react-intl';
import { useCoBrandingParams } from '../hooks/useCobrandingParams';

const GreenClaimHeaderLogo = () => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const { dynamicConfig } = useCoBrandingParams(location);
  const { site } = dynamicConfig;

  return (
    <a
      href={'//' + config.portalUrl}
      className="no-underline w-auto float-left block"
    >
      <img
        src={config.siteLogo}
        alt="Green Claim Logo"
        className="ml-12 sm:ml-0 h-15 sm:h-20 inline-block"
      />
      {site !== 'vlucht-vertraagd-be' && site !== 'tix-be' ? (
        <p className="text-xxs md:text-xs text-gray-400 m-0 block w-full text-right">
          {formatMessage(messages.partOfYource)}
          <YourceLogo className="pl-5 sm:ml-0 h-15 sm:h-25 inline-block opacity-50 m-0" />
        </p>
      ) : null}
    </a>
  );
};

export default GreenClaimHeaderLogo;
