const config = require('./../config.json');

module.exports = {
  resolveSiteTheme: () => {
    if (
      ['vliegtickets', 'wtc'].some(domain =>
        config.calculatorDomain.includes(domain)
      )
    )
      return 'otravo';
    if (
      ['claimservice'].some(domain => config.calculatorDomain.includes(domain))
    )
      return 'tix';

    if (['airrefund'].some(domain => config.type.includes(domain)))
      return 'airrefund';

    if (['airrefund'].some(domain => config.calculatorDomain.includes(domain)))
      return 'airrefund';

    return 'greenclaim';
  },
};
