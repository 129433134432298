// React specific
import React from 'react';

// Gatsby specific
import { Link } from 'gatsby';
/**
 * A navigation link component for navigating from the header to a specific site
 * @param props
 * @returns
 */
const NavLink = (props: {
  children: string;
  to: string;
  className: string;
}): JSX.Element => {
  return (
    <Link
      className={
        props.className || 'text-root font-bold text-black no-underline mx-15'
      }
      activeClassName="nav-link-active"
      to={props.to}
    >
      {props.children}
    </Link>
  );
};

export default NavLink;
