import React from 'react';
import { useCoBrandingParams } from '../hooks/useCobrandingParams';

// Logos
import AirRefundHeaderLogo from './AirRefundHeaderLogo';
import visaLogo from '../assets/images/visa-logo.svg';
import AbancaLogo from '../assets/images/abanca-logo.svg';
import ActivoBank from '../assets/images/activobank-logo.svg';
import BkbLogo from '../assets/images/bkb-logo.svg';
import PbzcardLogo from '../assets/images/pbzcard-logo.svg';
import SlspLogo from '../assets/images/slsp-logo.svg';
import CaixaLogo from '../assets/images/caixa-logo-v2.svg';

const AirRefundVisaLogo = () => {
  const { dynamicConfig } = useCoBrandingParams();
  const windowSearch =
    typeof window !== 'undefined' ? window?.location?.search : '';
  const hasVisaCardIssuer = windowSearch?.includes('visaCardIssuer');

  const getBankLogo = () => {
    if (windowSearch?.includes('abanca')) return AbancaLogo;
    if (windowSearch?.includes('activobank')) return ActivoBank;
    if (windowSearch?.includes('caixa-geral-de-depositos')) return CaixaLogo;
    if (windowSearch?.includes('bkb')) return BkbLogo;
    if (windowSearch?.includes('pbzcard')) return PbzcardLogo;
    if (windowSearch?.includes('slsp')) return SlspLogo;
  };

  return (
    <>
      {!hasVisaCardIssuer ? (
        <>
          <AirRefundHeaderLogo />
          <div
            style={{
              borderLeft: '1px solid #CBD5E1',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <img
              src={visaLogo}
              alt="visaLogo"
              style={{ marginLeft: '1.25rem', height: '1.5rem' }}
            />
          </div>
        </>
      ) : (
        <>
          <a
            href={'//' + dynamicConfig.portalUrl}
            className="no-underline w-auto float-left block flex items-center"
          >
            <img
              src={getBankLogo()}
              alt="visaLogo"
              className="ml-12 sm:ml-0 h-35 sm:h-50 inline-block mr-20"
              style={{ height: '2.5rem' }}
            />
          </a>
          <div
            style={{
              borderLeft: '1px solid #CBD5E1',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <img
              src={visaLogo}
              alt=""
              style={{ marginLeft: '1.25rem', height: '1.5rem' }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default AirRefundVisaLogo;
