import React from 'react';
import config from '../config.json';
import { useCoBrandingParams } from '../hooks/useCobrandingParams';

const AirRefundHeaderLogo = () => {
  const { logoUrl, dynamicConfig } = useCoBrandingParams();

  return (
    <a
      href={'//' + dynamicConfig.portalUrl}
      className="no-underline w-auto float-left block flex items-center"
    >
      <img
        src={logoUrl || config.siteLogo}
        alt="Logo"
        className="ml-12 sm:ml-0 h-35 sm:h-50 inline-block mr-20"
      />
    </a>
  );
};

export default AirRefundHeaderLogo;
