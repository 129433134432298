import React from 'react';
import { Router } from '@reach/router';
import PropTypes from 'prop-types';
import {
  normalizeRoute,
  translateRoute,
  useTranslateRoutes,
} from '../utils/routes';
import translatable from '../utils/propTypes/translatable';

const TranslatedRouter = ({ children, ...props }) => {
  const translatedRoutes = useTranslateRoutes(children);

  return (
    <Router
      {...props}
      basepath={normalizeRoute(
        props?.basepath?.id ? translateRoute(props.basepath) : props.basepath
      )}
    >
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child, {
          ...child.props,
          path: translatedRoutes[index],
          basepath: props.basepath,
        })
      )}
    </Router>
  );
};

TranslatedRouter.propTypes = {
  basepath: PropTypes.oneOfType([PropTypes.string, translatable]),
  children: PropTypes.any,
};

export default TranslatedRouter;
