// React specific
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

// CSS styling
import clsx from 'clsx';

// Third party libs
import { isEmpty } from 'lodash';

// Component
import messages from '../messages/common';
import Button from './Button';

// Services and state
import { useJsonApi } from '../services/GlobalStore/GlobalStore';
import routes from '../messages/routes';
import { useTranslatedNavigate } from '../utils/routes';
import { jsonApiAxios } from '../services/api';
import { useGlobalState } from '../services/GlobalStore/GlobalStore';
import { setAccount } from '../services/GlobalStore/reducer';
import HeaderMenu from './HeaderMenu';

import { resolveSiteTheme } from '../utils/resolveSiteTheme';
import GreenClaimHeaderLogo from './GreenClaimHeaderLogo';
import AirRefundVisaLogo from './AirRefundVisaLogo';
import AirRefundHeaderLogo from './AirRefundHeaderLogo';
import OTAHeaderLogo from './OTAHeaderLogo';
import useAuthenticate from '../hooks/useAuthenticate';

// Icons
import Icon from './Icon';
import closeIcon from '../assets/icons/close-icon.svg';
import hamburgerIcon from '../assets/icons/hamburger-icon.svg';

import translations from '../translations/main.json';
import { CachePolicies } from 'use-http';
import config from '../config';

const siteTheme = resolveSiteTheme();

/**
 * Global header component for the application
 * @param {*} param0
 * @returns
 */
const LayoutHeader = ({ showStepper, location, hideMenu = false }) => {
  const { formatMessage } = useIntl();
  const { isLoggedIn, doLogout } = useAuthenticate();
  const translatedNavigate = useTranslatedNavigate();
  const [, dispatch] = useGlobalState();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const {
    data: accounts,
    get: getAccount,
    loading: loadingAccount,
  } = useJsonApi(`/accounts/me`, {
    cachePolicy: CachePolicies.NO_CACHE,
    cacheLife: 0,
    queryParams: {
      u: '1',
      include: 'country',
    },
  });

  function navigateToLogin() {
    translatedNavigate(['/', routes.login]);
  }

  function logout() {
    jsonApiAxios
      .post(
        '/logout',
        {},
        {
          noSerialize: true,
        }
      )
      .then(() => {
        doLogout();
        navigateToLogin();
      })
      .catch(() => {
        doLogout();
        navigateToLogin();
      });
  }

  useEffect(() => {
    if (location?.pathname !== routes.accounts) {
      setMobileMenuOpen(false);
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (isLoggedIn) {
      getAccount();
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  useEffect(() => {
    if (!isEmpty(accounts)) {
      dispatch(setAccount(`/accounts/me-${accounts[0].id}`, accounts[0]));
      localStorage.setItem('customerId', accounts[0].id);
    }
    // eslint-disable-next-line
  }, [loadingAccount]);

  const isThankYouPage = location.pathname.includes(
    translations['cc4.seo.route.passenger-thank-you']
  );

  const isPayment =
    location.pathname.includes(
      translations['cc4.seo.route.no-payment-received']
    ) ||
    location.pathname.includes(
      translations['cc4.seo.route.payment-received']
    ) ||
    location.pathname.includes(translations['cc4.seo.route.payment-status']);

  const openMobileMenu = () => {
    // Remove overlapping of relative position from searh bar
    // NEED to find a better way and fix it
    setMobileMenuOpen(!mobileMenuOpen);
    const searcBar = document.getElementsByClassName('search-bar-class');
    if (searcBar?.length) {
      for (let i = 0; i < searcBar?.length; i++) {
        searcBar[i].style.zIndex = !mobileMenuOpen ? -1 : 1;
      }
    }
  };

  return (
    <header
      className={clsx(
        'flex sm:static bg-white shadow-menu-mobile sm:shadow-menu w-full h-60 sm:h-70 z-10',
        {
          ['sm:mb-50']: !showStepper,
          ['fixed top-0 h-full items-start']: mobileMenuOpen,
          ['items-center']: !mobileMenuOpen,
        }
      )}
    >
      <div
        className={clsx(
          'flex-col sm:flex-row items-center w-full mx-auto xl:px-105 flex justify-between',
          {
            ['container']:
              location?.pathname?.includes(
                translations['cc4.seo.route.account']
              ) === false,
            ['max-w-1180']:
              location?.pathname?.includes(
                translations['cc4.seo.route.account']
              ) === true,
          }
        )}
      >
        <div className={'flex w-full sm:w-auto justify-between'}>
          {siteTheme === 'greenclaim' ? (
            <GreenClaimHeaderLogo />
          ) : siteTheme === 'airrefund' ? (
            config?.isVisaSite ? (
              <AirRefundVisaLogo />
            ) : (
              <AirRefundHeaderLogo />
            )
          ) : (
            <OTAHeaderLogo />
          )}
          {isLoggedIn ? (
            <Icon
              src={!mobileMenuOpen ? hamburgerIcon : closeIcon}
              className="color-gray-250 p-15 flex sm:hidden"
              // eslint-disable-next-line
              onClick={openMobileMenu}
            />
          ) : null}
        </div>
        {(isThankYouPage || isPayment) && !isLoggedIn ? (
          <Button
            width="auto"
            theme="secondary"
            size="small"
            onClick={navigateToLogin}
          >
            {formatMessage(messages.loginText)}
          </Button>
        ) : !hideMenu || (isLoggedIn && isThankYouPage) ? (
          <HeaderMenu menuOpen={mobileMenuOpen} logout={logout} />
        ) : null}
      </div>
    </header>
  );
};

LayoutHeader.propTypes = {
  hideMenu: PropTypes.bool,
  location: PropTypes.object,
  showStepper: PropTypes.bool,
};

export default LayoutHeader;
